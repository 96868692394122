import { useEffect, useState } from "react";
import { Modal, Row, Form, Col, Button, Alert } from "react-bootstrap";
import RemoveModal from "./RemoveModal";
import toastService from "../../../../_services/toastService";
import SatAPI from "../../../../_services/sat";
import { useSelector } from "react-redux";

const api = SatAPI();

function AssignmentEdit({ data = {}, hideModal, saveData, removeAssignment }) {
  const snapshot = useSelector(state => state.snapshot.fileName);
  const [collegaID, setCollegaID] = useState(data.collegaID || {});
  const [collega, setCollega] = useState(data.collega || {});
  const [allCollegas, setAllCollegas] = useState([]);
  const [percentageOverride, setPercentageOverride] = useState(data.percentageOverride || 0);
  const [comment, setComment] = useState(data.comment || "");
  const [removeModal, setRemoveModal] = useState(null);

  const load = async () => {
    const loadedCollegas = await api.getActiveCollegas(snapshot);
    if (localStorage.getItem("SAT-typeSorting") === "firstname") {
      loadedCollegas.sort((a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName));
    } else {
      loadedCollegas.sort((a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName));
    }
    setAllCollegas(loadedCollegas);
  };

  const openRemoveModal = assignment => {
    setRemoveModal(
      <RemoveModal
        hideModal={() => {
          setRemoveModal(null);
          hideModal();
        }}
        data={assignment}
        removeAssignment={removeAssignment}
      />,
    );
  };

  useEffect(() => {
    load();
  }, [data]);

  useEffect(() => {
    // this is used to also update the collega sub-object
    // this isn't used in this module but is used in the list
    setCollega(allCollegas.find(c => c.ID === collegaID));
  }, [collegaID, allCollegas]);

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      hideModal();
      data.collegaID = collegaID;
      data.percentageOverride = percentageOverride;
      data.comment = comment;
      data.collega = collega;
      saveData(data);
    } catch (error) {
      toastService.send({ title: "An error occured", message: error });
    }
  };

  return (
    <Modal
      show
      onHide={() => {
        hideModal();
      }}
      backdrop="static"
      keyboard={false}
      centered
      size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Taaktoewijzing bewerken</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {removeModal}
        <Row>
          <Col xs={12}>
            {((collega || {}).uNumber === "u0134997" || (collega || {}).uNumber === "u0148838" || (collega || {}).uNumber === "u0157807") && (
              <Alert variant="info">
                Hey, jij daar! Denk je ook eens goed na over wie deze app voor jou gemaakt heeft?&nbsp;
                <i className="far fa-smile-wink" />
              </Alert>
            )}
            <Form onSubmit={onFormSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Docent</Form.Label>
                <Form.Select onChange={e => setCollegaID(parseInt(e.target.value, 10))} value={collegaID}>
                  {allCollegas.map(c => (
                    <option key={c.ID} value={c.ID}>
                      {c.firstName} {c.lastName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Percentage Aanpassing</Form.Label>
                <Form.Control type="number" required min={0} step="0.5" onChange={e => setPercentageOverride(parseFloat(e.target.value, 10))} value={percentageOverride} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Commentaar</Form.Label>
                <Form.Control type="text" onChange={e => setComment(e.target.value)} value={comment} />
              </Form.Group>

              <Button variant="primary" type="submit">
                <span>
                  <i className="far fa-edit" />
                  &nbsp;Bewerk
                </span>
              </Button>
              <Button variant="danger" className="ms-3 float-end" onClick={() => openRemoveModal(data)}>
                <i className="far fa-trash" />
                &nbsp;Verwijder
              </Button>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default AssignmentEdit;
