import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  NavItem,
  FloatingLabel,
  Form,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { filterErrors } from "./_helpers/calculations";
import { live, goBack } from "./redux/snapshot";
import SatAPI from "./_services/sat";
import toastService from "./_services/toastService";

const api = SatAPI();

function AppNav() {
  const snapshot = useSelector((state) => state.snapshot.fileName);
  const [listSnapshots, setListSnapshots] = useState([]);
  const [bg, setBg] = useState("white");
  const [variant, setVariant] = useState("light");
  const [errors, setErrors] = useState(0);
  const [creatingSnapshot, setCreatingSnapshot] = useState(false);

  const [hasToWarnMaartje, setHasToWarnMaartje] = useState(false);
  const [sandboxEnvironment, setSandboxEnvironment] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("isSATUser") === "true") {
      api.checkRules().then((e) => {
        setErrors(filterErrors(e).length);
      });
    }
    loadSnapshotList();
    if (
      (localStorage.getItem("name") === "Brent Pulmans" ||
        localStorage.getItem("name") === "Jitse Beyens") &&
      window.location.href.indexOf("sat.thomasmore.be") > -1
    ) {
      setHasToWarnMaartje(true);
    }

    if (window.location.href.indexOf("sat-sandbox.thomasmore.be") > -1) {
      setSandboxEnvironment(true);
      setBg("info");
      setVariant("dark");
    }
  }, []);

  const turnDark = () => {
    if (localStorage.getItem("isSATUser") === "true") {
      setBg("dark");
      setVariant("dark");
    }
  };

  const turnLight = () => {
    if (sandboxEnvironment) {
      setBg("info");
      setVariant("dark");
    } else {
      setBg("white");
      setVariant("light");
    }
  };

  const loadSnapshotList = () => {
    if (localStorage.getItem("isSATUser") !== "true") {
      api.getPublishedSnapshots().then((snapshots) => {
        const sortedsnapshots = snapshots.sort((a, b) => {
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }

          // names must be equal
          return 0;
        });
        setListSnapshots(sortedsnapshots);
        loadSnapshot(
          snapshots.find((x) => x.is_the_ultimate_source_of_truth === true).name
        );
      }).catch((e) => {
        console.error(e);
        setListSnapshots([])
      });
    } else {
      api.getSnapshots().then((snapshots) => {
        const sortedsnapshots = snapshots.sort((a, b) => {
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }

          // names must be equal
          return 0;
        });
        setListSnapshots(sortedsnapshots);
      }).catch((e) => {
        console.error(e);
        setListSnapshots([])
      });
    }
  };

  const loadSnapshot = (s) => {
    if (s === "Live") {
      s = null;
      turnLight();

      dispatch(live());
      return;
    }

    turnDark();
    console.log(s);
    dispatch(goBack(s));
  };

  const createSnapshot = () => {
    setCreatingSnapshot(true);
    api
      .createSnapshot()
      .then((snapshot) => {
        toastService.send({
          title: "Success",
          message: "Snapshot " + snapshot.name + " aangemaakt",
        });
        loadSnapshotList();
      })
      .catch((exception) => {
        console.error(exception);
      })
      .finally(() => {
        setCreatingSnapshot(false);
      });
  };

  return (
    <Navbar bg={bg} variant={variant} expand="lg" className="mb-5" fixed="top">
      {localStorage.getItem("isSATUser") === "true" ? (
        <Container>
          <LinkContainer to="/">
            {snapshot ? (
              <Navbar.Brand>
                <img
                  src="/Back_to_the_sat.png"
                  alt="Back to the SAT"
                  height="140"
                />
              </Navbar.Brand>
            ) : (
              <Navbar.Brand>
                {hasToWarnMaartje && <span>THE REAL DEAL</span>}
                {sandboxEnvironment && (
                  <span className="font-bold">SANDBOX Testing Environment</span>
                )}
                {!hasToWarnMaartje && !sandboxEnvironment && (
                  <img src="/logo-2023.svg" alt="Thomas More" height="53" />
                )}
              </Navbar.Brand>
            )}
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <NavItem className="normal-link">
              <LinkContainer to="/">
                <Nav.Link>
                  <span className={sandboxEnvironment && "sandbox-link"}>
                    Home
                  </span>
                </Nav.Link>
              </LinkContainer>
            </NavItem>

            <NavItem className="normal-link">
              <LinkContainer to="/myperformancescheme">
                <Nav.Link>
                  <span className={sandboxEnvironment && "sandbox-link"}>
                    Mijn prestatieregeling
                  </span>
                </Nav.Link>
              </LinkContainer>
            </NavItem>

            <NavDropdown
              title={
                sandboxEnvironment ? (
                  <span className="sandbox-link">Toewijzing</span>
                ) : (
                  <span>Toewijzing</span>
                )
              }
              id="basic-nav-dropdown"
            >
              <LinkContainer to="/toewijzing/olas">
                <NavDropdown.Item>OPO-toewijzingen</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/toewijzing/teachers">
                <NavDropdown.Item>Personal view</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/toewijzing/tasks">
                <NavDropdown.Item>Taak-toewijzingen</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>

            <NavDropdown
              title={
                sandboxEnvironment ? (
                  <span className="sandbox-link">Beheer</span>
                ) : (
                  <span>Beheer</span>
                )
              }
              id="basic-nav-dropdown"
            >
              <LinkContainer to="/collegas">
                <NavDropdown.Item>Docenten</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/programmes">
                <NavDropdown.Item>Opleidingen</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/phases">
                <NavDropdown.Item>Fasen</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/opos">
                <NavDropdown.Item>OPO&apos;s</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/parallels">
                <NavDropdown.Item>Parallelvakken</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/tasks">
                <NavDropdown.Item>Taken</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/reports">
                <NavDropdown.Item>Rapportage</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>

            <NavItem className="normal-link rules">
              <LinkContainer to="/rules/check">
                {errors === 0 ? (
                  <Nav.Link>
                    <i className="fad fa-check-circle" />{" "}
                    <span className="number">0</span>
                  </Nav.Link>
                ) : (
                  <Nav.Link>
                    <i className="fad fa-exclamation-triangle" />{" "}
                    <span className="number">{errors}</span>
                  </Nav.Link>
                )}
              </LinkContainer>
            </NavItem>
            <FloatingLabel
              controlId="floatingSelect"
              label="Snapshot"
              style={{ maxWidth: "350px" }}
            >
              <Form.Select
                aria-label="Floating label select example"
                onChange={(e) => loadSnapshot(e.target.value)}
                defaultValue={snapshot}
              >
                <option value={null}>Live</option>
                {listSnapshots.map((s) => (
                  <option key={s.ID} value={s.fileName}>
                    {s.name}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
            <NavItem
              className={sandboxEnvironment ? "normal-link nav-link sandbox-link" : "normal-link nav-link"}
            >
              {creatingSnapshot && <i className="fad fa-spinner-third fa-spin" />}
              {creatingSnapshot || <i className="fas fa-camera" onClick={createSnapshot} style={{ cursor: "pointer" }} />}
            </NavItem>
            <NavDropdown
              title={
                sandboxEnvironment ? (
                  <i className="fas fa-cog sandbox-link" />
                ) : (
                  <i className="fas fa-cog" />
                )
              }
              id="basic-nav-dropdown"
            >
              <LinkContainer to="/taskcategories">
                <NavDropdown.Item>Taakcategorieën</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/labels">
                <NavDropdown.Item>Labels</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/campusses">
                <NavDropdown.Item>Campussen</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/trajectories">
                <NavDropdown.Item>Trajecten</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/parameters">
                <NavDropdown.Item>Parameters</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/reports">
                <NavDropdown.Item>Rapportage</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/manage-performancescheme">
                <NavDropdown.Item>Snapshots</NavDropdown.Item>
              </LinkContainer>
              <hr />
              <LinkContainer to="/personalsetup">
                <NavDropdown.Item>
                  <i className="fas fa-user" />
                  &nbsp;Mijn instellingen
                </NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <NavItem className="normal-link rules">
              <a
                target="_blank"
                href="https://www.youtube.com/watch?v=Q1JeRQnpkM0"
                rel="noreferrer"
              >
                <i className="fad fa-hurricane" />
              </a>
            </NavItem>
            <NavItem className="normal-link">
              <LinkContainer to="/docs">
                <Nav.Link>
                  {
                    sandboxEnvironment ? (
                      <i className="fas fa-book sandbox-link" />
                    ) : (
                      <i className="fas fa-book" />
                    )
                  }
                </Nav.Link>
              </LinkContainer>
            </NavItem>
          </Navbar.Collapse>
        </Container>
      ) : (
        <Container>
          <LinkContainer to="/myperformancescheme">
            <Navbar.Brand>
              <img src="/logo-2023.svg" alt="Thomas More" height="53" />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <NavItem className="normal-link">
              <LinkContainer to="/myperformancescheme">
                <Nav.Link>Mijn prestatieregeling</Nav.Link>
              </LinkContainer>
            </NavItem>
            <FloatingLabel
              controlId="floatingSelect"
              label="Snapshot"
              style={{ maxWidth: "350px" }}
            >
              <Form.Select
                aria-label="Floating label select example"
                onChange={(e) => loadSnapshot(e.target.value)}
                defaultValue={snapshot}
              >
                {listSnapshots.map((s) => (
                  <option key={s.ID} value={s.fileName}>
                    {s.name}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Navbar.Collapse>
        </Container>
      )}
    </Navbar>
  );
}

export default AppNav;
