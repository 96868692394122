import React, { useEffect, useState } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import toastService from "./_services/toastService";
import SatAPI from "./_services/sat";

const GuardedRoute = (props) => {
  const nav = useHistory();
  const isSATUser = localStorage.getItem("isSATUser");
  const api = SatAPI();

  useEffect(() => {
    api.getMyself().then((res) => {
        if(res.isSATUser && isSATUser === "true"){
            console.log("access granted")
        }
        if(!res.isSATUser){
            if(isSATUser === "true"){
            toastService.send({
                title: "Beware",
                message: "Tamper attempt detected, management has been notified.",
              });
            }
            nav.push("/");
        }
    })
  }, [])

  return (
    <Route path={props.path}>
      {props.children}
    </Route>
  );
};

export default GuardedRoute;
