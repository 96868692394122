import { useEffect, useState } from "react";
import { Modal, Row, Form, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import SatAPI from "../../_services/sat";
import toastService from "../../_services/toastService";

const api = SatAPI();

function EditModal({ data = {}, hideModal }) {
  const snapshot = useSelector(state => state.snapshot.fileName);

  const [name, setName] = useState(data.name || "");
  const [numberOfStudents, setNumberOfStudents] = useState(data.numberOfStudents || 1);
  const [numberOfClasses, setNumberOfClasses] = useState(data.numberOfClasses || 1);
  const [programmeID, setProgramme] = useState(data.programmeID || 0);
  const [programmes, setProgrammes] = useState([]);
  const [campusID, setCampus] = useState(data.campusID || 0);
  const [campusses, setCampusses] = useState([]);
  const [trajectoryID, setTrajectory] = useState(data.trajectoryID || 0);
  const [trajectories, setTrajectories] = useState([]);
  const [color, setColor] = useState(data.color || "#e6e6e6");

  useEffect(() => {
    api.getProgrammes(snapshot).then(
      res => setProgrammes(res.sort((a, b) => b.name < a.name)),
      error => toastService.send({ title: "An error occured", message: error }),
    );
    api.getCampusses(snapshot).then(
      res => setCampusses(res.sort((a, b) => b.name < a.name)),
      error => toastService.send({ title: "An error occured", message: error }),
    );
    api.getTrajectories(snapshot).then(
      res => setTrajectories(res.sort((a, b) => b.name < a.name)),
      error => toastService.send({ title: "An error occured", message: error }),
    );
  }, [snapshot]);

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      if (data.ID) {
        await api.updatePhase(data.ID, { name, numberOfStudents, programmeID, campusID, trajectoryID, numberOfClasses, color });
      } else {
        await api.createPhase({ name, numberOfStudents, programmeID, campusID, trajectoryID, numberOfClasses, color });
      }

      //api.createSnapshot();
      toastService.send({ title: "Success", message: "Fase is opgeslagen" });
      hideModal();
    } catch (error) {
      toastService.send({ title: "An error occured", message: error.toString() });
    }
  };
  return (
    <Modal
      show
      onHide={() => {
        hideModal();
      }}
      backdrop="static"
      keyboard={false}
      centered
      size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Fase {data.ID ? "bewerken" : "aanmaken"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <Form onSubmit={onFormSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Naam</Form.Label>
                <Form.Control type="text" required onChange={e => setName(e.target.value)} value={name} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Kleur Kanban</Form.Label>
                <Form.Control type="color" required onChange={e => setColor(e.target.value)} value={color} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Aantal Studenten</Form.Label>
                <Form.Control type="number" required onChange={e => setNumberOfStudents(parseInt(e.target.value, 10))} value={numberOfStudents} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Aantal Klassen</Form.Label>
                <Form.Control type="number" required onChange={e => setNumberOfClasses(parseInt(e.target.value, 10))} value={numberOfClasses} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Opleiding</Form.Label>
                <Form.Select onChange={e => setProgramme(parseInt(e.target.value, 10))} value={programmeID}>
                  <option value={null}>Selecteer een opleiding</option>
                  {programmes.map(p => (
                    <option key={p.ID} value={p.ID} selected={p.ID === programmeID}>
                      {p.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Campus</Form.Label>
                <Form.Select onChange={e => setCampus(parseInt(e.target.value, 10))} value={campusID}>
                  <option value={null}>Selecteer een campus</option>
                  {campusses.map(c => (
                    <option key={c.ID} value={c.ID} selected={c.ID === campusID}>
                      {c.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Traject</Form.Label>
                <Form.Select onChange={e => setTrajectory(parseInt(e.target.value, 10))} value={trajectoryID}>
                  <option value={null}>Selecteer een traject</option>
                  {trajectories.map(c => (
                    <option key={c.ID} value={c.ID} selected={c.ID === trajectoryID}>
                      {c.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <p className="font-weight-bold">
                Aantal studenten per klas: {(numberOfStudents / numberOfClasses).toLocaleString("nl-BE", { maximumFractionDigits: 2 })}
              </p>

              <Button variant="primary" type="submit" disabled={snapshot}>
                {data.ID ? (
                  <span>
                    <i className="far fa-edit" />&nbsp;Bewerk
                  </span>
                ) : (
                  <span>
                    <i className="far fa-plus" />&nbsp;Aanmaken{" "}
                  </span>
                )}
              </Button>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default EditModal;
