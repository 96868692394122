import { useEffect } from "react";
import { Modal, Row, Col, Table } from "react-bootstrap";
// import { calculateOPOVTEWorstCase, calculateOPOVTEBestCase } from "../../../../_helpers/calculations";

function AssignmentEdit({ data = {}, hideModal }) {
  /* const [worstCaseVTE, setVTEWorstCase] = useState(0);
    const [bestCaseVTE, setVTEBestCase] = useState(0);
    const load = async () => {
        const newWorstCaseVTE = await calculateOPOVTEWorstCase([data]);
        setVTEWorstCase(newWorstCaseVTE);
        const newBestCaseVTE = await calculateOPOVTEBestCase([data]);
        setVTEBestCase(newBestCaseVTE);
    }; */

  useEffect(() => {
    // load();
  }, [data]);

  return (
    <Modal
      show
      onHide={() => {
        hideModal();
      }}
      backdrop="static"
      keyboard={false}
      centered
      size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Info - {data.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            {/* <p className="font-weight-bold">Maximale begroting OPO: worstCaseVTE VTE</p>
                        <p className="font-weight-bold">Minimale begroting OPO: bestCaseVTE VTE</p> */}
            <p>Studiepunten: {data.ectsCredits}</p>
            <p>Z-code OPO: {data.opo.zCode}</p>
            <p>Z-code OLA: {data.zCode ? data.zCode : "onbekend"}</p>
            <p>Docenten per klas: {data.teachersPerClass}</p>
            <p>Contacturen:</p>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Q1</th>
                  <th>Q2</th>
                  <th>Q3</th>
                  <th>Q4</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{data.contactQ1}</td>
                  <td>{data.contactQ2}</td>
                  <td>{data.contactQ3}</td>
                  <td>{data.contactQ4}</td>
                </tr>
              </tbody>
            </Table>
            <p>Is een stage: {data.internship ? "ja" : "nee"}</p>
            <p>Fase(s):</p>
            {data.planPerPhase ?
              <div>
                {data.phases.map(olaPhase => (
                  <div className="mt-3 ola-override-item">
                    <p><i className="fas fa-square" style={{ color: olaPhase.phase.color }} /> <span className="text-decoration-underline">{olaPhase.phase.name}</span> <i className="fas fa-square" style={{ color: olaPhase.phase.color }} /></p>
                    <p>Studiepunten: {(olaPhase.ectsCreditsOverride != null && olaPhase.ectsCreditsOverride != data.ectsCredits) ? <b className="text-danger">{olaPhase.ectsCreditsOverride}</b> : data.ectsCredits}</p>
                    <p>Z-code OPO: {(olaPhase.opoZCodeOverride != null && olaPhase.opoZCodeOverride != data.opo.zCode) ? <b className="text-danger">{olaPhase.opoZCodeOverride}</b> : data.opo.zCode}</p>
                    <p>Z-code OLA: {(olaPhase.olaZCodeOverride != null && olaPhase.olaZCodeOverride != data.zCode) ? <b className="text-danger">{olaPhase.olaZCodeOverride}</b> : (data.zCode ? data.zCode : "onbekend")}</p>
                    <p>Docenten per klas: {(olaPhase.teachersPerClassOverride != null && olaPhase.teachersPerClassOverride != data.teachersPerClass) ? <b className="text-danger">{olaPhase.teachersPerClassOverride}</b> : data.teachersPerClass}</p>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Q1</th>
                          <th>Q2</th>
                          <th>Q3</th>
                          <th>Q4</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{(olaPhase.contactQ1Override != null && olaPhase.contactQ1Override != data.contactQ1) ? <b className="text-danger">{olaPhase.contactQ1Override}</b> : data.contactQ1}</td>
                          <td>{(olaPhase.contactQ2Override != null && olaPhase.contactQ2Override != data.contactQ2) ? <b className="text-danger">{olaPhase.contactQ2Override}</b> : data.contactQ2}</td>
                          <td>{(olaPhase.contactQ3Override != null && olaPhase.contactQ3Override != data.contactQ3) ? <b className="text-danger">{olaPhase.contactQ3Override}</b> : data.contactQ3}</td>
                          <td>{(olaPhase.contactQ4Override != null && olaPhase.contactQ4Override != data.contactQ4) ? <b className="text-danger">{olaPhase.contactQ4Override}</b> : data.contactQ4}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <p>{olaPhase.phase.numberOfStudents + olaPhase.surplus} studenten - {olaPhase.numberOfClassesOverride || olaPhase.phase.numberOfClasses} klassen</p>
                  </div>
                ))}
              </div>
              :
              <ul>
                {data.phases.map(phase => (
                  <li>
                    {phase.phase.name} ({phase.phase.numberOfStudents + phase.surplus} studenten - {phase.numberOfClassesOverride || phase.phase.numberOfClasses}{" "}
                    klassen)
                  </li>
                ))}
              </ul>
            }

            <p>
              Opmerkingen:
              <br />
              <span className="text-muted">{data.opo.comments}</span>
            </p>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default AssignmentEdit;
