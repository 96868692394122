import { Container, Row, Col, Nav, Tab } from "react-bootstrap";

function Docs() {
  return (
    <div className="docs">
      <Tab.Container defaultActiveKey="introduction">
        <Row>
          <Col md={3} className="bg-light p-3 border-end">
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="introduction">SAT docs</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="release-notes">Release notes</Nav.Link>
                <Nav className="flex-column ms-3">
                  <Nav.Item>
                    <Nav.Link eventKey="release-march-2025">
                      Maart 2025
                    </Nav.Link>
                  </Nav.Item>
                  {/*<Nav.Item>
                  <Nav.Link eventKey="release-april-2025">April 2025</Nav.Link>
                </Nav.Item>*/}
                </Nav>
              </Nav.Item>
            </Nav>
          </Col>

          <Col md={9}>
            <Tab.Content>
              <Tab.Pane eventKey="introduction">
                <h2>SAT docs</h2>
                <p>
                  Welkom op de documentatie van SAT. Op deze pagina kan je een
                  antwoord vinden op de meest voorkomende vragen. Daarnaast vind
                  je er de release notes terug van de webapplicatie (release
                  notes vanaf maart 2025).
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="release-notes">
                <h2>Release notes</h2>
                <p>
                  In deze sectie worden de zogenaamde release notes toegevoegd.
                  Release notes bevatten informatie over de wijzigingen,
                  verbeteringen en nieuwe functies in elke update van SAT. Ze
                  helpen je om op de hoogte te blijven van recente
                  ontwikkelingen en eventuele aanpassingen in de webapplicatie.
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="release-march-2025">
                <h2>Release Notes - Maart 2025</h2>
                <p>Dit zijn de nieuwigheden in de release(s) van maart 2025:</p>
                <ul>
                  <li>
                    <a href="#250301">Vernieuwde navigatie</a>
                  </li>
                  <li>
                    <a href="#250302">Documentatie</a>
                  </li>
                  <li>
                    <a href="#250303">Snapshots</a>
                  </li>
                  <li>
                    <a href="#collega-inactive">Docenten inactief maken</a>
                  </li>
                  <li>
                    <a href="#250304">Plannen per phase</a>
                  </li>
                  <li>
                    <a href="#250305">Downloaden prestatieregeling als PDF</a>
                  </li>
                  <li>
                    <a href="#250306">Visuele indicaties in lijst docenten</a>
                  </li>
                  <li>
                    <a href="#250307">U-nummer in export docenten</a>
                  </li>
                  <li>
                    <a href="#250308">Pair teaching</a>
                  </li>
                </ul>

                <h3 className="mt-4" id="250301">
                  Vernieuwde navigatie
                </h3>
                <p>
                  De navigatie van SAT is aangepast om gemakkelijker het
                  overzicht te kunnen bewaren. Instellingen waar je normaal
                  gesproken minder frequent tot zelden mee bezig bent (zoals de
                  algemene parameters), zijn &apos;verhuisd&apos; van
                  &apos;Beheer&apos; naar het tandwieltje (
                  <i className="fas fa-cog" />
                  ), waar eerder enkel &apos;Mijn instellingen&apos; onder te
                  vinden was.
                </p>
                <p>
                  De navigatie ziet er nu als volgt uit, bij het klikken op{" "}
                  <i className="fas fa-cog" />:
                </p>
                <img
                  src="/images/docs/2025-03/2025-03_Navigation.png"
                  className="img-fluid"
                  alt="Vernieuwde navigatie"
                />

                <h3 className="mt-4" id="250302">
                  Documentatie
                </h3>
                <p>
                  Ook de pagina die je op dit moment leest, is splinternieuw. In
                  de documentatie zullen onder meer de release notes verschijnen
                  (in plaats van in het SAT Teamskanaal). Je kan de documentatie
                  bereiken door op het icoontje van het boek (
                  <i className="fas fa-book" />) te klikken helemaal rechts in
                  de navigatie.
                </p>

                <h3 className="mt-4" id="250303">
                  Snapshots
                </h3>
                <h4>Aanmaken van snapshots</h4>
                <p>
                  Snapshots zijn terug van weggeweest! In het verleen werden
                  snapshot al gebruikt om historische data te bekijken. De
                  grote lijnen zijn hetzelfde gebleven maar er zijn lichte
                  wijzigingen gebeurd om extra functionaliteiten op te vangen.
                </p>
                <p>
                  Snapshots worden in deze nieuwe versie <b>niet</b> meer automatisch
                  aangemaakt. Het automatisch aanmaken van snapshots zorgde vroeger voor een ongebruiksvriendelijke en zeer
                  lange lijst waar niemand wijzer uit werd. In deze iteratie zal
                  je zelf je snapshots moeten maken door op het camera icoontje
                  (<i className="fas fa-camera" />) naast de snapshotlijst
                  bovenaan in de menubalk te klikken. Dit start het process om
                  een nieuwe snapshot te creëren. Eenmaal dit proces verwerkt
                  is (duurt gemiddeld minder dan 10 seconden), zal je een melding
                  krijgen dat de snapshot is aangemaakt en zal deze ook in de
                  dropdownlijst verschijnen.
                </p>
                <img
                  src="/images/docs/2025-03/2025-03_Snapshots_Confirm_and_menu.png"
                  className="img-fluid"
                  alt="Bevestiging van creatie en menubalk"
                  title="Bevestiging van creatie en menubalk met lijst en icoon"
                />
                <p>
                  De benaming van de snapshots is opgebouwd uit het jaar, de
                  maand, de dag, het uur, de minuut en de seconde (in UTC)
                  waarop deze aangemaakt is.
                </p>
                <h4>Inladen van snapshots</h4>
                <p>
                  Het inladen van snapshots werkt nog steeds zoals voorheen,
                  maar hier nog even de belangrijkste zaken op een rij:{" "}
                </p>
                <ul>
                  <li>
                    Je laadt de snapshot in door hem bovenaan in de dropdown menu
                    in de navigatiebalk te selecteren.
                  </li>
                  <li>
                    Je krijgt daarna enkel data te zien die in deze snapshot
                    bewaard is.
                  </li>
                  <li>
                    Je kan enkel data bekijken, data aanpassen is niet mogelijk!
                  </li>
                </ul>
                <h4>Snapshots beheren</h4>
                <p>
                  Je kan vanaf nu ook de snapshots beheren! In de instellingen
                  menu, vind je de optie "Snapshots" terug.
                </p>
                <img
                  src="/images/docs/2025-03/2025-03_Snapshots_Manage.png"
                  className="img-fluid"
                  alt="Instellingen menu met snapshots onderaan"
                  title="Instellingen menu met snapshots onderaan"
                />
                <p>
                  Op deze pagina kan je snapshots (de)publiceren voor docenten
                  of volledig verwijderen. Om te publiceren klik je het vinkje
                  aan bij de respectievelijke snapshot, om te verwijderen klik
                  je op de rode knop met de vuilbak.
                </p>
                <p>
                  <b>
                    LET OP: momenteel is hier nog geen extra confirmation check.
                    Let dus goed op voor je een snapshot verwijdert gezien deze
                    voor altijd weg is!
                  </b>
                </p>

                <img
                  src="/images/docs/2025-03/2025-03_Snapshots_Manage_table.png"
                  className="img-fluid"
                  alt="Snapshot beheer tabel"
                  title="Snapshot beheer tabel"
                />
                <h4>Voor docenten</h4>
                <p>
                  Dit nieuwe systeem kan meerdere snapshots publiceren, waardoor
                  het vanaf nu ook mogelijk is voor docenten om hun vorige
                  prestatieregelingen opnieuw te bekijken (startend bij
                  2024-2025).
                </p>
                <p>
                  {" "}
                  Docenten krijgen dezelfde dropdown in hun menubalk te zien,
                  maar zullen enkel kunnen kiezen uit een lijst van
                  gepubliceerde snapshots, zij hebben <b>geen</b> toegang tot de
                  live omgeving en/of niet-gepubliceerde snapshots
                </p>

                <h3 id="collega-inactive" className="mt-4">
                  Docenten inactief maken
                </h3>
                <p>
                  Stel dat er een docent is die het volgende academiejaar
                  afwezig zal zijn, bijvoorbeeld wegens langdurige ziekte, en
                  die je zeker niet wilt inplannen in SAT, dan is het nu
                  mogelijk om die docent op verschillende plekken in de
                  applicatie automatisch uit te filteren. Dat wordt mogelijk
                  door bij de instellingen van een docent het vinkje
                  &apos;Inactief (verborgen in applicatie)&apos; aan te vinken
                  en de wijziging op te slaan.
                </p>
                <img
                  src="/images/docs/2025-03/2025-03_Collega_inactive_checkbox.png"
                  className="img-fluid"
                  alt="Collega inactief maken"
                />
                <p>
                  Overal in SAT wordt de docent nu weggefilterd. De enige twee
                  uitzonderingen zijn de overzichtslijst van docenten (zodat je
                  de docent terug actief kan maken) en op de pagina &apos;Mijn
                  prestatieregeling&apos;.
                </p>

                <h3 className="mt-4" id="250304">
                  Plannen per fase
                </h3>
                <p>
                  Tot nu toe was het in SAT mogelijk om meerdere fasen te
                  koppelen aan een OLA, maar een docent plannen voor een
                  specifieke fase kon tot dusver nog niet.
                </p>
                <p>
                  Met deze nieuwe functionaliteit kan je er voor kiezen om
                  docenten op een specifieke fase te plannen, waarbij het ook
                  mogelijk wordt om per fase verschillende contacturen in te
                  geven onder andere.
                </p>
                <p>
                  Bij het aanmaken of aanpassen van een OLA (binnen een OPO)
                  dien je het vinkje &apos;Plan per fase&apos; aan te vinken.
                  Duid vervolgens in de lijst van fasen de fasen aan waarin het
                  OLA ingericht wordt.
                </p>
                <img
                  src="/images/docs/2025-03/2025-03_Plan_per_phase_choose-phases.png"
                  className="img-fluid"
                  alt="Plan per fase - kies fasen"
                />
                <p>
                  Vervolgens verschijnen de gekozen fasen en kan je per fase
                  afwijkingen ten opzichte van het gewone OLA ingeven
                  (bijvoorbeeld een andere z-code, andere contacturen,
                  enzovoort). Denk er aan je aanpassingen op te slaan.
                </p>
                <img
                  src="/images/docs/2025-03/2025-03_Plan_per_phase_overrides.png"
                  className="img-fluid"
                  alt="Plan per fase - geef afwijkingen in"
                />
                <p>
                  Na het aanmaken of aanpassen van je OLA en de daarbij horende
                  fasen, kan je naar de pagina &apos;OPO-toewijzingen&apos; gaan
                  om docenten toe te wijzen.
                </p>
                <p>
                  Je zal merken dat de blokken die SAT voorziet om toe te wijzen
                  enerzijds de naam van de fase bevatten en anderzijds de
                  achtergrondkleur van de fase hebben gekregen.
                </p>
                <img
                  src="/images/docs/2025-03/2025-03_Plan_per_phase_empty_assignments.png"
                  className="img-fluid"
                  alt="Plan per fase - lege toewijzingen"
                />
                <p>
                  Wanneer we in het voorbeeld hierboven klikken op een oranje
                  blokje voor de fase &apos;2CCS&apos; en vervolgens een docent
                  toekennen, dan zal deze docent aan deze fase gekoppeld worden
                  voor dit OLA. Visueel geeft dat het volgende resultaat:
                </p>
                <img
                  src="/images/docs/2025-03/2025-03_Plan_per_phase_first_assignment.png"
                  className="img-fluid"
                  alt="Plan per fase - eerste toewijzing"
                />
                <p>
                  Voor OLA&apos;s waarbij &apos;Plan per fase&apos; is
                  aangevinkt, zal de docent in zijn prestatieregeling niet
                  alleen het vak en zijn/haar collega&apos;s te zien krijgen,
                  maar ook welke collega&apos;s lesgeven aan welke fase en aan
                  welke fase hij/zij zelf les moet geven. In het voorbeeld
                  hieronder werd Brent Pulmans twee keer ingepland (één keer op
                  2IOT, &&n keer op 2CCS) en werd Jitse Beyens één keer
                  ingepland (op 2CCS).
                </p>
                <img
                  src="/images/docs/2025-03/2025-03_Plan_per_phase_performance_scheme.png"
                  className="img-fluid"
                  alt="Plan per fase - prestatieregeling docent"
                />
                <p>
                  Als je tijdens het plannen snel info wilt bekijken over het
                  OLA dat je aan het inplannen bent, dan kan je op de pagina
                  &apos;OPO-toewijzingen&apos; klikken op de naam van het OLA.
                  Er zal zich een scherm openen met meer info.
                </p>
                <img
                  src="/images/docs/2025-03/2025-03_Plan_per_phase_OLA_name_link.png"
                  className="img-fluid"
                  alt="Klik op naam OLA voor meer info"
                />
                <p>
                  In het scherm dat zich opent zie je bovenaan de informatie van
                  het hoofd-OLA. In de delen eronder zie je info over de fasen.
                  Afwijkingen ten opzichte van het hoofd-OLA worden in het rood
                  getoond.
                </p>
                <img
                  src="/images/docs/2025-03/2025-03_Plan_per_phase_OLA_info.png"
                  className="img-fluid"
                  alt="OLA info"
                />

                <h3 className="mt-4" id="250305">
                  Download prestatieregeling als PDF
                </h3>
                <p>
                  Op de pagina &apos;Mijn prestatieregeling&apos; kan je vanaf
                  nu de getoonde prestatieregeling opslaan als PDF door middel
                  van de knop &apos;Download als PDF&apos; rechts bovenaan het
                  scherm.
                </p>
                <img
                  src="/images/docs/2025-03/2025-03_Download_performance_scheme_as_PDF.png"
                  className="img-fluid"
                  alt="Download prestatieregeling als PDF"
                />

                <h3 className="mt-4" id="250306">
                  Visuele indicaties in lijst docenten
                </h3>
                <p>
                  In de lijst met docenten werden extra visuele indicaties
                  toegevoegd om snel te kunnen zien welke docenten inactief zijn
                  (<a href="#collega-inactive">zie hierboven</a>), wie
                  bewerkrechten heeft in SAT, enzovoort. Zie je even door het
                  bos de bomen niet meer? Rechtsboven kan je een legende van de
                  gebruikte symbolen terugvinden door op het vraagteken te
                  klikken.
                </p>
                <img
                  src="/images/docs/2025-03/2025-03_Legend_collegas_icons.png"
                  className="img-fluid"
                  alt="Download prestatieregeling als PDF"
                />
                <h3 className="mt-4" id="250307">
                  U-nummer in export docenten
                </h3>
                <p>
                  In de Excel-export van docenten werd een kolom toegevoegd waarin het u-nummer van de docent in kwestie getoond wordt.
                </p>
                <h3 className="mt-4" id="250308">
                  Pair teaching
                </h3>
                <p>
                  Om pair teaching mogelijk te maken zonder manueel het aantal klassen van iedere fase aan te moeten passen, is er een nieuw veld genaamd 'Docenten per klas' toegevoegd op OLA-niveau. Binnen de logica van '<a href="#250304">Plannen per fase</a>' kan hier ook op niveau van de OLA-fase van afgeweken worden indien gewenst. De standaardwaarde voor 'Docenten per klas' is 1.
                </p>
                <img
                  src="/images/docs/2025-03/2025-03_Set_teachers_per_class_on_OLA.png"
                  className="img-fluid"
                  alt="Docenten per klas instellen op OLA"
                />
                <p>
                  In de toewijzingen zal het aantal vakjes voor toewijzingen verhoogd worden op basis van het aantal docenten per klas. In het voorbeeld hieronder is te zien dat voor de fase 2CCS er 2 klassen zijn met elk 2 docenten.
                </p>
                <img
                  src="/images/docs/2025-03/2025-03_Teachers_per_class_in_assignments.png"
                  className="img-fluid"
                  alt="Docenten per klas in toewijzingen"
                />
                <p>
                  Wanneer geklikt wordt op de naam van het OLA, dan zal deze info ook hier verschijnen. Bij afwijkingen op de OLA-fase wordt het aantal docenten per klas in het rood getoond, zoals hieronder te zien is voor de fase 2CCS.
                </p>
                <img
                  src="/images/docs/2025-03/2025-03_Teachers_per_class_override.png"
                  className="img-fluid"
                  alt="Docenten per klas in info-scherm OLA"
                />
                <p>
                  <span className="text-danger fw-bold">Momenteel worden de percentages bij pair teaching nog berekend zoals voor normale toewijzingen. In een volgende release van SAT zal in de VTE-berekening ook rekening gehouden worden met pair teaching (bvb. 2 docenten per klas betekent slechts de helft van het normale percentage).</span>
                </p>
              </Tab.Pane>
              {/*<Tab.Pane eventKey="release-april-2025">
              <h2>Release Notes - April 2025</h2>
              <p>Dit zijn de nieuwigheden in de release van xx april 2025:</p>
            </Tab.Pane>*/}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}

export default Docs;
