import { useEffect, useState, useRef } from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import SatAPI from "../../_services/sat";
import { MultiSelect } from "react-multi-select-component";
import { forEach } from "lodash";
import { useSelector } from "react-redux";

const api = SatAPI();

function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

function EditOLA({ data = {}, zCodeOPO, removeOLA, programmeID, saveData }) {
  const snapshot = useSelector(state => state.snapshot.fileName);
  const [name, setName] = useState(data.name || "");
  const [contactQ1, setContactQ1] = useState(data.contactQ1 || 0);
  const [contactQ2, setContactQ2] = useState(data.contactQ2 || 0);
  const [contactQ3, setContactQ3] = useState(data.contactQ3 || 0);
  const [contactQ4, setContactQ4] = useState(data.contactQ4 || 0);
  const [semester, setSemester] = useState(data.semester || 0);
  const prevSemester = usePrevious(semester);
  const [ectsCredits, setECTSCredits] = useState(data.ectsCredits || 0);
  const [realECTSCredits, setRealECTSCredits] = useState(data.realECTSCredits || 0);
  const [phases, setPhases] = useState(data.phases || []); // OLAPhase
  const [selectedPhases, setSelectedPhases] = useState(
    data.phases
      ? data.phases
        .filter(item => item?.phase)
        .map(({ phase }) => ({ value: phase.ID, label: phase.name }))
      : []
  );
  const [allPhases, setAllPhases] = useState([]);
  const [internship, setInternship] = useState(data.internship || false);
  const [planPerPhase, setPlanPerPhase] = useState(data.planPerPhase || false);
  const [zCode, setZCode] = useState(data.zCode || "");
  const [internshipPercentage, setInternshipPercentage] = useState(data.internshipPercentage || 0);
  const [teachersPerClass, setTeachersPerClass] = useState(data.teachersPerClass || 1);

  useEffect(() => {
    const fetchData = async () => {
      const classResponse = await api.getPhases(programmeID, snapshot);
      classResponse.sort((a, b) => b.name < a.name);
      setAllPhases(classResponse);
    };
    fetchData();
  }, [programmeID]);

  useEffect(() => {
    if (!prevSemester || prevSemester === semester) {
      return;
    }

    // sem 1 to sem 2
    if (prevSemester === 1 && semester === 2) {
      setContactQ3(contactQ1);
      setContactQ4(contactQ2);
      setContactQ1(0);
      setContactQ2(0);
    }

    // sem 2 to sem 1
    if (prevSemester === 2 && semester === 1) {
      setContactQ1(contactQ3);
      setContactQ2(contactQ4);
      setContactQ3(0);
      setContactQ4(0);
    }

    // year to sem 1
    if (prevSemester === 0 && semester === 1) {
      setContactQ3(0);
      setContactQ4(0);
    }

    // year to sem 2
    if (prevSemester === 0 && semester === 2) {
      setContactQ1(0);
      setContactQ1(0);
    }
  }, [semester]);

  useEffect(() => {
    data.name = name;
    data.contactQ1 = contactQ1;
    data.contactQ2 = contactQ2;
    data.contactQ3 = contactQ3;
    data.contactQ4 = contactQ4;
    data.semester = semester;
    data.ectsCredits = ectsCredits;
    data.phases = phases;
    data.internship = internship;
    data.planPerPhase = planPerPhase;
    data.zCode = zCode;
    data.realECTSCredits = realECTSCredits;
    data.internshipPercentage = internshipPercentage;
    data.teachersPerClass = teachersPerClass;
    saveData(data);
  }, [name, contactQ1, contactQ2, contactQ3, contactQ4, semester, ectsCredits, phases, internship, planPerPhase, zCode, realECTSCredits, internshipPercentage, teachersPerClass]);

  const setPhasesByDropdown = (ddPhases) => {
    let newPhases = [];
    phases.forEach((x) => {
      if (ddPhases.map(y => y.value).includes(x.phaseID)) {
        newPhases.push(x);
      }
    })
    ddPhases.forEach(x => {
      if (!newPhases.map(x => x.phaseID).includes(x.value)) {
        newPhases.push(
          {
            phaseID: x.value,
            surplus: 0,
            numberOfClassesOverride: 0,
            comment: null,
            contactQ1Override: null,
            contactQ2Override: null,
            contactQ3Override: null,
            contactQ4Override: null,
            olaZCodeOverride: null,
            opoZCodeOverride: null,
            ectsCreditsOverride: null,
            realECTSCreditsOverride: null,
            semesterOverride: null,
            teachersPerClassOverride: null,
            olaID: data.ID || 0,
          }
        );
      }
    })
    setSelectedPhases(ddPhases);
    setPhases(newPhases);
  }

  const setSurplus = (phaseID, surplus) => {
    const newPhases = phases.map(l => {
      if (l.phaseID === phaseID) {
        l.surplus = surplus;
      }
      return l;
    });
    setPhases(newPhases);
  };

  const setNumberOfClassesOverride = (phase, numberOfClassesOverride) => {
    if (phase.numberOfClasses === numberOfClassesOverride) {
      numberOfClassesOverride = null;
    }
    const newPhases = phases.map(l => {
      if (l.phaseID === phase.ID) {
        l.numberOfClassesOverride = numberOfClassesOverride;
      }
      return l;
    });
    setPhases(newPhases);
  };

  const setContactQ1Override = (phase, contactOverride) => {
    if (phase.contactQ1 === contactOverride) {
      contactOverride = null;
    }
    const newPhases = phases.map(l => {
      if (l.phaseID === phase.ID) {
        l.contactQ1Override = contactOverride;
      }
      return l;
    });
    setPhases(newPhases);
  };

  const setContactQ2Override = (phase, contactOverride) => {
    if (phase.contactQ2 === contactOverride) {
      contactOverride = null;
    }
    const newPhases = phases.map(l => {
      if (l.phaseID === phase.ID) {
        l.contactQ2Override = contactOverride;
      }
      return l;
    });
    setPhases(newPhases);
  };

  const setContactQ3Override = (phase, contactOverride) => {
    if (phase.contactQ3 === contactOverride) {
      contactOverride = null;
    }
    const newPhases = phases.map(l => {
      if (l.phaseID === phase.ID) {
        l.contactQ3Override = contactOverride;
      }
      return l;
    });
    setPhases(newPhases);
  };

  const setContactQ4Override = (phase, contactOverride) => {
    if (phase.contactQ4 === contactOverride) {
      contactOverride = null;
    }
    const newPhases = phases.map(l => {
      if (l.phaseID === phase.ID) {
        l.contactQ3Override = contactOverride;
      }
      return l;
    });
    setPhases(newPhases);
  };

  const setSemesterOverride = (phase, semesterOverride) => {
    const newSem = semesterOverride != null ? semesterOverride : semester;
    if (phase.semester === semesterOverride) {
      semesterOverride = null;
    }
    const newPhases = phases.map(l => {
      const prevSem = l.semesterOverride != null ? l.semesterOverride : semester;

      if (l.phaseID === phase.ID) {
        l.semesterOverride = semesterOverride;
        // sem 1 to sem 2
        if (prevSem === 1 && newSem === 2 && semester == 1) {
          l.contactQ3Override = l.contactQ1Override ? l.contactQ1Override : contactQ1;
          l.contactQ4Override = l.contactQ2Override ? l.contactQ2Override : contactQ2
          l.contactQ1Override = 0
          l.contactQ2Override = 0
        }

        if (prevSem === 1 && newSem === 2 && semester == 2) {
          l.contactQ3Override = l.contactQ1Override
          l.contactQ4Override = l.contactQ2Override
          l.contactQ1Override = null
          l.contactQ2Override = null
        }

        // sem 2 to sem 1
        if (prevSem === 2 && newSem === 1 && semester == 1) {
          l.contactQ1Override = l.contactQ3Override
          l.contactQ2Override = l.contactQ4Override
          l.contactQ3Override = null
          l.contactQ4Override = null
        }

        // sem 2 to sem 1
        if (prevSem === 2 && newSem === 1 && semester == 2) {
          l.contactQ1Override = l.contactQ3Override ? l.contactQ3Override : contactQ3
          l.contactQ2Override = l.contactQ4Override ? l.contactQ4Override : contactQ4
          l.contactQ3Override = 0
          l.contactQ4Override = 0
        }

        // year to sem 1
        if (prevSem === 0 && newSem === 1) {
          l.contactQ3Override = null
          l.contactQ4Override = null
        }

        // year to sem 2
        if (prevSem === 0 && newSem === 2) {
          l.contactQ1Override = null
          l.contactQ2Override = null
        }
      }
      return l;
    });
    setPhases(newPhases);
  };


  const setECTSCreditsOverride = (phase, ectsOverride) => {
    if (phase.ectsCredits === ectsOverride) {
      ectsOverride = null;
    }
    const newPhases = phases.map(l => {
      if (l.phaseID === phase.ID) {
        l.ectsCreditsOverride = ectsOverride;
      }
      return l;
    });
    setPhases(newPhases);
  };

  const setRealECTSCreditsOverride = (phase, ectsOverride) => {
    if (phase.realECTSCredits === ectsOverride) {
      ectsOverride = null;
    }
    const newPhases = phases.map(l => {
      if (l.phaseID === phase.ID) {
        l.realECTSCreditsOverride = ectsOverride;
      }
      return l;
    });
    setPhases(newPhases);
  };

  const setZCodeOlaOverride = (phase, zcodeOverride) => {
    if (phase.zCode === zcodeOverride) {
      zcodeOverride = null;
    }
    const newPhases = phases.map(l => {
      if (l.phaseID === phase.ID) {
        l.olaZCodeOverride = zcodeOverride;
      }
      return l;
    });
    setPhases(newPhases);
  };

  const setZCodeOpoOverride = (phase, zcodeOverride) => {
    if (zCodeOPO === zcodeOverride) {
      zcodeOverride = null;
    }
    const newPhases = phases.map(l => {
      if (l.phaseID === phase.ID) {
        l.opoZCodeOverride = zcodeOverride;
      }
      return l;
    });
    setPhases(newPhases);
  };

  const setTeachersPerClassOverride = (phase, teachersPerClassOverride) => {
    if (phase.teachersPerClass === teachersPerClassOverride) {
      teachersPerClassOverride = null;
    }
    const newPhases = phases.map(l => {
      if (l.phaseID === phase.ID) {
        l.teachersPerClassOverride = teachersPerClassOverride;
      }
      return l;
    });
    setPhases(newPhases);
  };

  const valueRenderer = (selected) => {
    if (!selected.length) {
      return <p>Select</p>;
    }

    return selected.length === 1 ? (
      <span className="pill">{selected[0].label}</span>
    ) : (
      selected.map(({ label }) => <span className="pill" key={label}>{label}</span>)
    );
  };

  return (
    <Row className="border m-3 p-2">
      <Col xs={11}>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Naam</Form.Label>
            <Form.Control type="text" required onChange={e => setName(e.target.value)} value={name} />
          </Form.Group>
          <Row>
            <Col xs={3}>
              <Form.Group className="mb-3">
                <Form.Label>Semester</Form.Label>
                <Form.Select onChange={e => setSemester(parseInt(e.target.value, 10))} value={semester}>
                  <option value={0}>Jaarvak</option>
                  <option value={1}>Semester 1</option>
                  <option value={2}>Semester 2</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={3}>
              <Form.Group className="mb-3">
                <Form.Label>Studiepunten SAT</Form.Label>
                <Form.Control type="number" min="0" required onChange={e => setECTSCredits(parseInt(e.target.value, 10))} value={ectsCredits} />
              </Form.Group>
            </Col>
            <Col xs={3}>
              <Form.Group className="mb-3">
                <Form.Label>Studiepunten ECTS</Form.Label>
                <Form.Control type="number" min="0" required onChange={e => setRealECTSCredits(parseInt(e.target.value, 10))} value={realECTSCredits} />
              </Form.Group>
            </Col>
            <Col xs={3}>
              <Form.Group className="mb-3">
                <Form.Label>Z-code OLA</Form.Label>
                <Form.Control type="text" required onChange={e => setZCode(e.target.value)} value={zCode} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <Form.Group className="mb-3">
                <Form.Check type="checkbox" label="Stage" checked={internship} onChange={e => setInternship(e.target.checked)} />
              </Form.Group>
            </Col>
            {internship && (
              <>
                <Col xs={6}>
                  <Form.Label>Percentage per stage</Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    step="0.01"
                    required
                    onChange={e => setInternshipPercentage(parseFloat(e.target.value, 10))}
                    value={internshipPercentage}
                  />
                </Col>
              </>
            )}
          </Row>
          {internship || (
            <>
              <Form.Label>Contacturen</Form.Label>
              <Row>
                <Col xs={3}>
                  <Form.Label>Q1</Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    step="0.5"
                    required
                    onChange={e => setContactQ1(parseFloat(e.target.value, 10))}
                    value={contactQ1}
                    disabled={data.semester === 2}
                  />
                </Col>
                <Col xs={3}>
                  <Form.Label>Q2</Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    step="0.5"
                    required
                    onChange={e => setContactQ2(parseFloat(e.target.value, 10))}
                    value={contactQ2}
                    disabled={data.semester === 2}
                  />
                </Col>
                <Col xs={3}>
                  <Form.Label>Q3</Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    step="0.5"
                    required
                    onChange={e => setContactQ3(parseFloat(e.target.value, 10))}
                    value={contactQ3}
                    disabled={data.semester === 1}
                  />
                </Col>
                <Col xs={3}>
                  <Form.Label>Q4</Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    step="0.5"
                    required
                    onChange={e => setContactQ4(parseFloat(e.target.value, 10))}
                    value={contactQ4}
                    disabled={data.semester === 1}
                  />
                </Col>
              </Row>
            </>
          )}
          <br />
          <Row>
            <Col xs={3}>
              <Form.Group className="mb-3">
                <Form.Label>Docenten per klas</Form.Label>
                <Form.Control type="number" min="0" required onChange={e => setTeachersPerClass(parseInt(e.target.value, 10))} value={teachersPerClass} />
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Check type="checkbox" label="Plan per fase" checked={planPerPhase} onChange={e => setPlanPerPhase(e.target.checked)} />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label>Fasen</Form.Label>
            <br />
            <MultiSelect
              options={allPhases.map(({ ID, name }) => ({ value: ID, label: name })).sort((a, b) => a.label.localeCompare(b.label))}
              value={selectedPhases} onChange={setPhasesByDropdown} labelledBy="Select" className="programme-selector" valueRenderer={valueRenderer} />

            {allPhases.length === 0 && <p>Selecteer eerst een opleiding</p>}
            {allPhases.filter(x => phases.map((x) => x.phaseID).includes(x.ID)).sort((a, b) => a.name.localeCompare(b.name)).map(c => (
              <Row key={c.ID} className="mt-3 ola-override-item">
                <Col xs={12}>
                  <p className="text-decoration-underline">{c.name}</p>

                  {phases.find(l => l.phaseID === c.ID) && (
                    <Row>
                      <Col xs={9} style={{ marginTop: 0, marginBottom: 10 }}>

                        <Form.Group className="mb-3 surplus-studenten" controlId="formPlaintext">
                          <p className="mb-1">Klassen</p>
                          {c.numberOfStudents} +{" "}
                          <Form.Control
                            inline
                            type="number"
                            required
                            onChange={e => setSurplus(c.ID, parseInt(e.target.value, 10))}
                            value={phases.find(l => l.phaseID === c.ID).surplus}
                          />{" "}
                          studenten /{" "}
                          <Form.Control
                            type="number"
                            required
                            onChange={e => setNumberOfClassesOverride(c, parseInt(e.target.value, 10))}
                            value={
                              phases.find(l => l.phaseID === c.ID).numberOfClassesOverride != null
                                ? phases.find(l => l.phaseID === c.ID).numberOfClassesOverride
                                : c.numberOfClasses
                            }
                          />{" "}
                          klassen
                        </Form.Group>
                      </Col>

                      <Col xs={3} className={!planPerPhase && "d-none"}>
                        <Form.Group className="mb-3">
                          <Form.Label>Z-code OPO</Form.Label>
                          <Form.Control type="text" required
                            onChange={e => setZCodeOpoOverride(c, e.target.value)}
                            value={phases.find(l => l.phaseID === c.ID).opoZCodeOverride ?
                              phases.find(l => l.phaseID === c.ID).opoZCodeOverride :
                              zCodeOPO
                            } />
                        </Form.Group>
                      </Col>

                      <Col xs={3} className={!planPerPhase && "d-none"}>
                        <Form.Group className="mb-3">
                          <Form.Label>Semester</Form.Label>
                          <Form.Select
                            onChange={e => setSemesterOverride(c, parseInt(e.target.value, 10))}
                            value={phases.find(l => l.phaseID === c.ID).semesterOverride != null ?
                              phases.find(l => l.phaseID === c.ID).semesterOverride : semester
                            }>
                            <option value={0}>Jaarvak</option>
                            <option value={1}>Semester 1</option>
                            <option value={2}>Semester 2</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col xs={3}>
                        <Form.Group className={planPerPhase ? "mb-3" : "mb-3 d-none"}>
                          <Form.Label>SP SAT</Form.Label>
                          <Form.Control type="number" min="0" required
                            onChange={e => setECTSCreditsOverride(c, parseInt(e.target.value, 10))}
                            value={phases.find(l => l.phaseID === c.ID).ectsCreditsOverride ?
                              phases.find(l => l.phaseID === c.ID).ectsCreditsOverride : ectsCredits
                            } />
                        </Form.Group>
                      </Col>
                      <Col xs={3}>
                        <Form.Group className={planPerPhase ? "mb-3" : "mb-3 d-none"}>
                          <Form.Label>SP ECTS</Form.Label>
                          <Form.Control type="number" min="0" required
                            onChange={e => setRealECTSCreditsOverride(c, parseInt(e.target.value, 10))}
                            value={phases.find(l => l.phaseID === c.ID).realECTSCreditsOverride ?
                              phases.find(l => l.phaseID === c.ID).realECTSCreditsOverride :
                              realECTSCredits
                            } />
                        </Form.Group>
                      </Col>
                      <Col xs={3}>
                        <Form.Group className={planPerPhase ? "mb-3" : "mb-3 d-none"}>
                          <Form.Label>Z-code OLA</Form.Label>
                          <Form.Control type="text" required
                            onChange={e => setZCodeOlaOverride(c, e.target.value)}
                            value={phases.find(l => l.phaseID === c.ID).olaZCodeOverride ?
                              phases.find(l => l.phaseID === c.ID).olaZCodeOverride :
                              zCode
                            } />
                        </Form.Group>
                      </Col>

                      <Col xs={3} className={!planPerPhase && "d-none"}>
                        <Form.Label>Q1</Form.Label>
                        <Form.Control
                          type="number"
                          min="0"
                          step="0.5"
                          required
                          onChange={e => setContactQ1Override(c, parseFloat(e.target.value, 10))}
                          value={phases.find(l => l.phaseID === c.ID).contactQ1Override != null ?
                            phases.find(l => l.phaseID === c.ID).contactQ1Override
                            : contactQ1
                          }
                          disabled={phases.find(l => l.phaseID === c.ID).semesterOverride != null ?
                            phases.find(l => l.phaseID === c.ID).semesterOverride == 2
                            : semester == 2}
                        />
                      </Col>
                      <Col xs={3} className={!planPerPhase && "d-none"}>
                        <Form.Label>Q2</Form.Label>
                        <Form.Control
                          type="number"
                          min="0"
                          step="0.5"
                          required
                          onChange={e => setContactQ2Override(c, parseFloat(e.target.value, 10))}
                          value={phases.find(l => l.phaseID === c.ID).contactQ2Override != null ?
                            phases.find(l => l.phaseID === c.ID).contactQ2Override
                            : contactQ2
                          }
                          disabled={phases.find(l => l.phaseID === c.ID).semesterOverride != null ?
                            phases.find(l => l.phaseID === c.ID).semesterOverride == 2
                            : semester == 2}
                        />
                      </Col>
                      <Col xs={3} className={!planPerPhase && "d-none"}>
                        <Form.Label>Q3</Form.Label>
                        <Form.Control
                          type="number"
                          min="0"
                          step="0.5"
                          required
                          onChange={e => setContactQ3Override(c, parseFloat(e.target.value, 10))}
                          value={phases.find(l => l.phaseID === c.ID).contactQ3Override != null ?
                            phases.find(l => l.phaseID === c.ID).contactQ3Override
                            : contactQ3
                          }
                          disabled={phases.find(l => l.phaseID === c.ID).semesterOverride != null ?
                            phases.find(l => l.phaseID === c.ID).semesterOverride == 1
                            : semester == 1}
                        />
                      </Col>
                      <Col xs={3} className={!planPerPhase && "d-none"}>
                        <Form.Label>Q4</Form.Label>
                        <Form.Control
                          type="number"
                          min="0"
                          step="0.5"
                          required
                          onChange={e => setContactQ4Override(c, parseFloat(e.target.value, 10))}
                          value={phases.find(l => l.phaseID === c.ID).contactQ4Override != null ?
                            phases.find(l => l.phaseID === c.ID).contactQ4Override
                            : contactQ4
                          }
                          disabled={phases.find(l => l.phaseID === c.ID).semesterOverride != null ?
                            phases.find(l => l.phaseID === c.ID).semesterOverride == 1
                            : semester == 1}
                        />
                      </Col>
                      <Col xs={3} className={!planPerPhase && "d-none"}>
                        <Form.Group className="mt-3 mb-3">
                          <Form.Label>Docenten per klas</Form.Label>
                          <Form.Control
                            type="number"
                            min="0"
                            step="1"
                            required
                            onChange={e => setTeachersPerClassOverride(c, parseInt(e.target.value, 10))}
                            value={phases.find(l => l.phaseID === c.ID).teachersPerClassOverride != null ?
                              phases.find(l => l.phaseID === c.ID).teachersPerClassOverride
                              : teachersPerClass
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            ))}
          </Form.Group>
        </Form>
      </Col>
      <Col xs={1}>
        <Button variant="danger" onClick={() => removeOLA()}>
          <i className="far fa-trash" />
        </Button>
      </Col>
    </Row>
  );
}

export default EditOLA;
