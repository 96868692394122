import SatAPI from "../../../_services/sat";
import { getCircularReplacer } from "../../../_helpers/json";

const api = SatAPI();

export const columnsDynamicData = (collegas, general) =>
  collegas.map(collega => ({
    id: collega.ID,
    collegaID: collega.ID,
    collega: [collega],
    name: `${collega.firstName} ${collega.lastName}`,
    color: collega.color || "#A162D8",
    limit: 10000,
    contactHoursQ1: collega.contactHoursQ1,
    contactHoursQ2: collega.contactHoursQ2,
    contactHoursQ3: collega.contactHoursQ3,
    contactHoursQ4: collega.contactHoursQ4,
    currentVTE: collega.currentVTE,
    filteredVTE: collega.filteredVTE,
    contactHoursLimit: general.contactHoursLimit,
    percentageAvailableOverall: collega.percentageInUnit - collega.leaveSchedule,
    percentageAvailableFiltered: collega.percentageAvailableFiltered,
    filteredProgrammesLength: collega.filteredProgrammesLength,
    show: collega.show, // for filtering
    taskIds: [
      ...collega.olaAssignments.map(assignment => ({
        id: assignment.ID,
        assignmentID: assignment.ID,
        ola: assignment.ola.name,
        planPerPhase: assignment.ola.planPerPhase,
        phase: assignment.phase?.name,
        idColumn: collega.ID,
        text: `${collega.firstName} ${collega.lastName}`,
        color: assignment.ola.phases[0].phase.color,
        contactHoursQ1: assignment.ola.contactQ1,
        contactHoursQ2: assignment.ola.contactQ2,
        contactHoursQ3: assignment.ola.contactQ3,
        contactHoursQ4: assignment.ola.contactQ4,
        opoResponsible: assignment.opoResponsible,
        internship: assignment.ola.internship,
        internshipStudents: assignment.internshipStudents,
        show: assignment.show,
        type: "ola",
        percentageOverride: assignment.percentageOverride,
        comment: assignment.comment,
      })),
      ...collega.taskAssignments.map(assignment => ({
        id: assignment.ID,
        assignmentID: assignment.ID,
        ola: assignment.task.name,
        planPerPhase: false,
        phase: null,
        idColumn: collega.ID,
        text: `${collega.firstName} ${collega.lastName}`,
        color: "#e6e6e6",
        contactHoursQ1: 0,
        contactHoursQ2: 0,
        contactHoursQ3: 0,
        contactHoursQ4: 0,
        internshipStudents: 0,
        show: assignment.show,
        type: "task",
        percentageOverride: 0,
        comment: assignment.comment,
      })),
    ],
  }));

export const getNewKanbanAssignment = (columns, olaAssignments, taskAssignments) => {
  // copy data
  // const olaAssignments = JSON.parse(JSON.stringify(oldOlaAssignments, getCircularReplacer()));
  // const taskAssignments = JSON.parse(JSON.stringify(oldTaskAssignments, getCircularReplacer()));

  console.log(olaAssignments);
  const cols = [...columns].map(collega => {
    if (collega.taskIds.length > 0) {
      collega.taskIds.forEach(task => {
        if (task.type === "ola") {
          const assignment = olaAssignments.find(i => i.ID === task.assignmentID);
          if (assignment.collegaID !== collega.collegaID) {
            assignment.collegaID = collega.collegaID;
            assignment.collega = collega;
          }
        } else if (task.type === "task") {
          const assignment = taskAssignments.find(i => i.ID === task.assignmentID);
          if (assignment.collegaID !== collega.collegaID) {
            assignment.collegaID = collega.collegaID;
            assignment.collega = collega;
          }
        }
      });
    }
    return collega;
  });
  return { columns: cols, olaAssignments, taskAssignments };
};

export const saveChanges = async (columns, olaAssignments, taskAssignments) => {
  await Promise.all(
    columns.map(async collega => {
      if (collega.taskIds.length > 0) {
        await Promise.all(
          collega.taskIds.map(async task => {
            if (task.type === "ola") {
              const assignment = olaAssignments.find(i => i.ID === task.assignmentID);
              if (assignment.collegaID !== collega.collegaID) {
                console.log("Assignment", assignment.ID, "- from Collega", assignment.collegaID, "to", collega.collegaID);
                console.log("Before:", assignment);
                assignment.collegaID = collega.collegaID;
                assignment.collega = collega;
                console.log("After:", assignment);
                await api.updateAssignment(assignment.ID, JSON.parse(JSON.stringify(assignment, getCircularReplacer())));
              }
            } else if (task.type === "task") {
              const assignment = taskAssignments.find(i => i.ID === task.assignmentID);
              if (assignment.collegaID !== collega.collegaID) {
                console.log("Task", assignment.ID, "- from Collega", assignment.collegaID, "to", collega.collegaID);
                console.log("Before:", assignment);
                assignment.collegaID = collega.collegaID;
                assignment.collega = collega;
                console.log("After:", assignment);
                await api.updateTaskAssignment(assignment.ID, JSON.parse(JSON.stringify(assignment, getCircularReplacer())));
              }
            }
          }),
        );
      }
    }),
  );
};
