import { useEffect, useState } from "react";
import { ListGroup, Form, InputGroup } from "react-bootstrap";
import SatAPI from "../../../../_services/sat";
import toastService from "../../../../_services/toastService";
import { useSelector } from "react-redux";

const api = SatAPI();

function DocentList({ selectDocent, programme, ola }) {
  const snapshot = useSelector(state => state.snapshot.fileName);
  const [collegasIP, setCollegasIP] = useState([]);
  const [collegasOther, setCollegasOther] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  const onDocentClick = docent => {
    selectDocent(docent);
  };

  const load = async () => {
    try {
      setLoading(true);
      const response = await api.getActiveCollegas(snapshot);
      if (localStorage.getItem("SAT-typeSorting") === "firstname") {
        response.sort((a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName));
      } else {
        response.sort((a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName));
      }

      response.filter(c => {
        console.log(c.programmes);
        return true;
      });

      const inProgram = response.filter(c => c.programmes.filter(p => p.programme.ID === programme.ID).length > 0);
      const other = response.filter(c => c.programmes.filter(p => p.programme.ID === programme.ID).length === 0);

      inProgram.forEach(collega => {
        collega.show = true;
        if (ola) {
          ola.assignments.forEach(assignment => {
            if (collega.ID === assignment.collegaID) {
              collega.show = false;
            }
          })
        }
      })

      other.forEach(collega => {
        collega.show = true;
        if (ola) {
          ola.assignments.forEach(assignment => {
            if (collega.ID === assignment.collegaID) {
              collega.show = false;
            }
          })
        }
      })

      setCollegasIP(inProgram);
      setCollegasOther(other);

      setLoading(false);
    } catch (error) {
      toastService.send({ title: "An error occured", message: error.toString() });
    }
  };

  const filter = list =>
    list.filter(c => {
      if (search === "") {
        return true;
      }
      return `${c.firstName} ${c.lastName} ${c.firstName} `.toLowerCase().includes(search.toLowerCase()); // clever trick to search on first and last name
    });

  useEffect(() => {
    load();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <div className="text-center">
        <i className="fad fa-spinner-third fa-spin fa-5x" />
      </div>
    );
  }

  return (
    <div>
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">
          <i className="fas fa-search" />
        </InputGroup.Text>
        <Form.Control type="text" required onChange={e => setSearch(e.target.value, 10)} value={search} />
      </InputGroup>
      <p className="mb-1">Docenten {programme.name}</p>
      <ListGroup>
        {filter(collegasIP, programme.ID).map(docent => (
          <ListGroup.Item key={docent.ID} action onClick={() => onDocentClick(docent)} className={docent.show ? "" : "d-none"}>
            {docent.firstName} {` `}
            {docent.lastName}
          </ListGroup.Item>
        ))}
      </ListGroup>
      <p className="mb-1">Overige Docenten</p>
      <ListGroup>
        {filter(collegasOther).map(docent => (
          <ListGroup.Item key={docent.ID} action onClick={() => onDocentClick(docent)} className={docent.show ? "" : "d-none"}>
            {docent.firstName} {` `}
            {docent.lastName}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
}

export default DocentList;
