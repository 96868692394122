import { useState } from "react";
import { Modal, Row, Form, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import MessageModal from "../_components/MessageModal";
import toastService from "../../_services/toastService";
import SatAPI from "../../_services/sat";

const api = SatAPI();

function RemoveModal({ data = {}, hideModal }) {
  const snapshot = useSelector(state => state.snapshot.fileName);

  const [messageModal, setMessageModal] = useState(null);

  const openMessageModal = (title, message) => {
    setMessageModal(
      <MessageModal
        hideModal={() => {
          setMessageModal(null);
        }}
        title={title}
        message={message}
        icon="fas fa-exclamation-triangle"
      />,
    );
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      const parallels = await api.getParallels(snapshot);
      let hasParallel = false;
      const assignments = await api.getAssignments(snapshot);
      let hasAssignment = false;
      data.olas.forEach(ola => {
        if (parallels.filter((parallel) => ola.ID === parallel.mainOLAID).length > 0 || parallels.filter((parallel) => ola.ID === parallel.subOLAID).length > 0) {
          hasParallel = true;
        }

        if (assignments.filter((assignment) => ola.ID === assignment.olaID).length > 0) {
          hasAssignment = true;
        }
      });

      if (hasParallel || hasAssignment) {
        if (hasParallel) {
          openMessageModal("Parallel bestaat nog", "Er bestaat nog een parallel voor een OLA van dit OPO. Verwijder de parallel alvorens het OPO te verwijderen.")
        }
        if (hasAssignment) {
          openMessageModal("Toewijzingen bestaan nog", "Er bestaan nog toewijzingen voor een OLA van dit OPO. Verwijder de toewijzingen alvorens het OPO te verwijderen.")
        }
      } else {

        if (data.ID) {
          await api.deleteOPO(data.ID);
        }

        //api.createSnapshot();
        toastService.send({ title: "Verwijderd", message: "OPO is verwijderd" });
        hideModal();
      }
    } catch (error) {
      toastService.send({ title: "An error occured", message: error });
    }
  };
  return (
    <Modal
      show
      onHide={() => {
        hideModal();
      }}
      backdrop="static"
      keyboard={false}
      centered
      size="lg">
      {messageModal}
      <Modal.Header closeButton>
        <Modal.Title>OPO verwijderen?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <Form onSubmit={onFormSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Ben je zeker dat je het OPO <span className="font-bold">{data.name}</span> wilt verwijderen?</Form.Label>
              </Form.Group>

              <Button variant="danger" type="submit" disabled={snapshot}>
                <span>
                  <i className="far fa-trash" />
                  &nbsp;Verwijder
                </span>
              </Button>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default RemoveModal;
