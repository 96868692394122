import { useState } from "react";
import { Modal, Row, Form, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import toastService from "../../_services/toastService";
import MessageModal from "../_components/MessageModal";
import SatAPI from "../../_services/sat";

const api = SatAPI();

function RemoveModal({ data = {}, hideModal }) {
  const snapshot = useSelector(state => state.snapshot.fileName);
  const [messageModal, setMessageModal] = useState(null);

  const openMessageModal = (title, message) => {
    setMessageModal(
      <MessageModal
        hideModal={() => {
          setMessageModal(null);
        }}
        title={title}
        message={message}
        icon="fas fa-exclamation-triangle"
      />,
    );
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      const tasks = await api.getTasks(snapshot);
      let hasTask = false;

      if (tasks.filter((task) => data.ID === task.categoryID).length > 0) {
        hasTask = true;
      }

      if (hasTask) {
        openMessageModal("Taak bestaat nog", "Er bestaat nog een taak voor deze taakcategorie. Ontkoppel de taakcategorie van deze taak alvorens de taakcategorie te verwijderen.")
      } else {
        if (data.ID) {
          await api.deleteTaskCategory(data.ID);
        }

        //api.createSnapshot();
        toastService.send({ title: "Verwijderd", message: "Taakcategorie is verwijderd" });
        hideModal();
      }
    } catch (error) {
      toastService.send({ title: "An error occured", message: error });
    }
  };
  return (
    <Modal
      show
      onHide={() => {
        hideModal();
      }}
      backdrop="static"
      keyboard={false}
      centered
      size="lg">
      {messageModal}
      <Modal.Header closeButton>
        <Modal.Title>Taakcategorie verwijderen?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <Form onSubmit={onFormSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Ben je zeker dat je de taakcategorie <span className="font-bold">{data.name}</span> wilt verwijderen?</Form.Label>
              </Form.Group>

              <Button variant="danger" type="submit" disabled={snapshot}>
                <span>
                  <i className="far fa-trash" />
                  &nbsp;Verwijder
                </span>
              </Button>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default RemoveModal;
