import { useEffect, useState } from "react";
import { Row, Container, Form, Col, Button, Table } from "react-bootstrap";
import ChangeSourceOfTruthModal from "./ChangeSourceOfTruthModal";
import toastService from "../../_services/toastService";
import SatAPI from "../../_services/sat";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";

const api = SatAPI();

function ManagePerfomancescheme() {
  const [snapshots, setListSnapshots] = useState([]);
  const [truthSource, setTruthSource] = useState({});

  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(null);

  const loadSnapshots = async () => {
    try {
      setLoading(true);

      setListSnapshots(await api.getSnapshots());
      //setTruthSource(await api.getSourceOfTruthSnapshot());
      console.warn(snapshots);
      setLoading(false);
    } catch (error) {
      if (typeof error.json === "function") {
        error.json().then((jsonError) => {
          toastService.send({ title: "Error", message: jsonError.message });
          console.log(jsonError);
        });
      } else {
        toastService.send({
          title: "Error",
          message: "Error bij verbinden naar server",
        });
      }
    }
  };

  const openModal = (data) => {
    setModal(
      <ChangeSourceOfTruthModal
        hideModal={() => {
          setModal(null);
          loadSnapshots();
        }}
        data={data}
      />
    );
  };

  useEffect(() => {
    loadSnapshots();
  }, []);

  const togglePublished = async (id) => {
    setLoading(true);
    let toupdate = snapshots.find(x => x.id === id);
    toupdate.is_the_ultimate_source_of_truth = !toupdate.is_the_ultimate_source_of_truth;
    await api.updateSnapshot(id, toupdate);
    await loadSnapshots();
    toastService.send({
      title: "Success",
      message: "Snapshot ge" + (toupdate.is_the_ultimate_source_of_truth? "" : "de") + "publiceerd",
    });
  }

  const removeSnapshot = async (id) => {
    setLoading(true);
    await api.deleteSnapshot(id);
    await loadSnapshots();
    toastService.send({
      title: "Success",
      message: "Snapshot verwijderd",
    });
  }

  if (loading) {
    return (
      <div className="text-center">
        <i className="fad fa-spinner-third fa-spin fa-5x" />
      </div>
    );
  }
  return (
    <Container>
      {modal}
      <Row>
        <h1>Snapshot beheer</h1>
      </Row>
      <br/>
      <Row>
        <Col>
          <Table bordered hover>
            <thead>
              <tr>
                <th>Naam snapshot</th>
                <th>Gepubliceerd</th>
                <th>Verwijder</th>
              </tr>
            </thead>
            <tbody>
            {snapshots.map((p) => (
              <tr>
                <td key={p.fileName} value={p.fileName}>
                  {p.name}
                </td>
                <td>
                  <FormCheckInput
                    value={p.id}
                    checked={p.is_the_ultimate_source_of_truth}
                    onClick={() => togglePublished(p.id)}
                  />
                </td>
                <td>
                  <Button variant="danger" onClick={() => removeSnapshot(p.id)}><i className="far fa-trash" /></Button></td>
              </tr>
            ))}
            </tbody>
          </Table>
          {/* <Form>
            <Form.Group className="mb-3">
              <Form.Label>Snapshot</Form.Label>
              <Form.Select onChange={e => setTruthSource(snapshots.filter(s => s.fileName === e.target.value)[0])} value={truthSource.fileName}>
                <option value={null}>Selecteer een snapshot</option>
                {snapshots.map(p => (
                  <option key={p.fileName} value={p.fileName}>
                    {p.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Button variant="success" type="button" onClick={() => openModal(truthSource)}>
              <i className="far fa-save" /> Opslaan
            </Button>
          </Form> */}
        </Col>
      </Row>
    </Container>
  );
}

export default ManagePerfomancescheme;
