import { useState } from "react";
import { Modal, Row, Form, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import toastService from "../../_services/toastService";
import SatAPI from "../../_services/sat";

const api = SatAPI();

function EditModalClassSize({ data = {}, hideModal }) {
  const snapshot = useSelector(state => state.snapshot.fileName);

  const [min, setMin] = useState(data.min || 0);
  const [max, setMax] = useState(data.max || 0);
  const [name, setName] = useState(data.name || "");
  const [shortName, setShortName] = useState(data.shortName || "");
  const [firstLoad, setFirstLoad] = useState(data.firstLoad || 0);
  const [parallelLoad, setParallelLoad] = useState(data.parallelLoad || 0);

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      if (data.ID) {
        await api.updateClassSizeParameter(data.ID, {
          min,
          max,
          name,
          shortName,
          firstLoad,
          parallelLoad,
        });
      } else {
        await api.createClassSizeParameter({ min, max, name, shortName, firstLoad, parallelLoad });
      }

      //api.createSnapshot();
      toastService.send({ title: "Success", message: "Klasgrootte belasting is opgeslagen" });
      hideModal();
    } catch (error) {
      toastService.send({ title: "An error occured", message: error });
    }
  };
  return (
    <Modal
      show
      onHide={() => {
        hideModal();
      }}
      backdrop="static"
      keyboard={false}
      centered
      size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Klasgrootte belasting {data.ID ? "bewerken" : "aanmaken"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <Form onSubmit={onFormSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Naam</Form.Label>
                <Form.Control type="text" required onChange={e => setName(e.target.value)} value={name} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Korte Naam</Form.Label>
                <Form.Control type="text" required onChange={e => setShortName(e.target.value)} value={shortName} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Minimaal aantal studenten</Form.Label>
                <Form.Control type="number" required onChange={e => setMin(parseInt(e.target.value, 10))} value={min} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Maximaal aantal studenten</Form.Label>
                <Form.Control type="number" required onChange={e => setMax(parseInt(e.target.value, 10))} value={max} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Factor eerste klas</Form.Label>
                <Form.Control type="number" required onChange={e => setFirstLoad(parseFloat(e.target.value, 10))} value={firstLoad} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Factor parallel klas</Form.Label>
                <Form.Control type="number" required onChange={e => setParallelLoad(parseFloat(e.target.value, 10))} value={parallelLoad} />
              </Form.Group>

              <Button variant="primary" type="submit" disabled={snapshot}>
                {data.ID ? (
                  <span>
                    <i className="far fa-edit" />&nbsp;Bewerk
                  </span>
                ) : (
                  <span>
                    <i className="far fa-plus" />&nbsp;Aanmaken{" "}
                  </span>
                )}
              </Button>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default EditModalClassSize;
