import { useEffect, useState } from "react";
import { Modal, Row, Form, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import SatAPI from "../../_services/sat";
import toastService from "../../_services/toastService";

const api = SatAPI();

function EditModal({ data = {}, hideModal }) {
  const snapshot = useSelector(state => state.snapshot.fileName);

  const [name, setName] = useState(data.name || "");
  const [percentage, setPercentage] = useState(data.percentage || 0);
  const [comment, setComment] = useState(data.comment || "");
  const [hasForfait, setHasForfait] = useState(data.hasForfait != null ? data.hasForfait : true);
  const [educationalActivity, setEducationalActivity] = useState(data.educationalActivity || false);
  const [categoryID, setTaskCategoryID] = useState(data.categoryID || 0);
  const [programmeIDs, setProgrammeIDs] = useState([]);
  const [labelIDs, setLabelIDs] = useState([]);
  const [allProgrammes, setAllProgrammes] = useState([]);
  const [taskCategories, setTaskCategories] = useState([]);
  const [allLabels, setAllLabels] = useState([]);

  useEffect(() => {
    api.getProgrammes(snapshot).then(
      res => setAllProgrammes(res),
      error => toastService.send({ title: "An error occured", message: error }),
    );
    api.getTaskCategories(snapshot).then(
      res => setTaskCategories(res),
      error => toastService.send({ title: "An error occured", message: error }),
    );
    api.getLabels(snapshot).then(
      res => setAllLabels(res),
      error => toastService.send({ title: "An error occured", message: error }),
    );
  }, [snapshot]);

  useEffect(() => {
    if (!data.programmes) return;
    setProgrammeIDs(data.programmes.map(p => p.ID));

    if (!data.labels) return;
    setLabelIDs(data.labels.map(l => l.ID));
  }, [data]);

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      let programmes = [];
      if (programmeIDs.length > 0) {
        programmes = programmeIDs.map(id => allProgrammes.find(p => p.ID === id));
      }
      let labels = [];
      if (labelIDs.length > 0) {
        labels = labelIDs.map(id => allLabels.find(l => l.ID === id));
      }
      if (data.ID) {
        await api.updateTask(data.ID, { name, programmes, comment, percentage, hasForfait, educationalActivity, categoryID, labels });
      } else {
        await api.createTask({ name, programmes, comment, percentage, hasForfait, educationalActivity, categoryID, labels });
      }

      //api.createSnapshot();
      toastService.send({ title: "Success", message: "Taak is opgeslagen" });
      hideModal();
    } catch (error) {
      toastService.send({ title: "An error occured", message: error.toString() });
    }
  };
  return (
    <Modal
      show
      onHide={() => {
        hideModal();
      }}
      backdrop="static"
      keyboard={false}
      centered
      size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Taak {data.ID ? "bewerken" : "aanmaken"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <Form onSubmit={onFormSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Naam</Form.Label>
                <Form.Control type="text" required onChange={e => setName(e.target.value)} value={name} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Percentage</Form.Label>
                <Form.Control type="number" required step="0.5" onChange={e => setPercentage(parseFloat(e.target.value, 10))} value={percentage} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Check type="checkbox" label="Forfait geven op taak" checked={hasForfait} onChange={e => setHasForfait(e.target.checked)} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Check type="checkbox" label="Onderwijsactiviteit" checked={educationalActivity} onChange={e => setEducationalActivity(e.target.checked)} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Categorie</Form.Label>
                <Form.Select onChange={e => setTaskCategoryID(parseInt(e.target.value, 10))} value={categoryID}>
                  <option value={null}>Selecteer een categorie</option>
                  {taskCategories.map(tc => (
                    <option key={tc.ID} value={tc.ID}>
                      {tc.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Opleidingen</Form.Label>
                {allProgrammes.map(programme => (
                  <Form.Check
                    key={programme.ID}
                    type="checkbox"
                    label={programme.name}
                    checked={programmeIDs.includes(programme.ID)}
                    onChange={() => {
                      if (programmeIDs.includes(programme.ID)) {
                        setProgrammeIDs(programmeIDs.filter(i => i !== programme.ID));
                      } else {
                        setProgrammeIDs([...programmeIDs, programme.ID]);
                      }
                    }}
                  />
                ))}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Labels</Form.Label>
                {allLabels.map(label => (
                  <Form.Check
                    key={label.ID}
                    type="checkbox"
                    label={label.name}
                    checked={labelIDs.includes(label.ID)}
                    onChange={() => {
                      if (labelIDs.includes(label.ID)) {
                        setLabelIDs(labelIDs.filter(i => i !== label.ID));
                      } else {
                        setLabelIDs([...labelIDs, label.ID]);
                      }
                    }}
                  />
                ))}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Commentaar</Form.Label>
                <Form.Control as="textarea" rows="3" onChange={e => setComment(e.target.value)} value={comment} />
              </Form.Group>

              <Button variant="primary" type="submit" disabled={snapshot}>
                {data.ID ? (
                  <span>
                    <i className="far fa-edit" /> Bewerk
                  </span>
                ) : (
                  <span>
                    <i className="far fa-plus" /> Aanmaken{" "}
                  </span>
                )}
              </Button>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default EditModal;
