import { useEffect, useState } from "react";
import { Modal, Row, Form, Col, Button } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";

import { useSelector } from "react-redux";
import toastService from "../../_services/toastService";
import SatAPI from "../../_services/sat";
import EditProgramme from "./EditProgramme";
import EditFinanceSource from "./EditFinanceSource";
import InvalidProgrammeModal from "./InvalidProgrammeModal";

const api = SatAPI();

const possibleAmbts = [
  { name: "Hoofdlector", label: "Hoofdlector", value: "Hoofdlector" },
  { name: "Lector", label: "Lector", value: "Lector" },
  { name: "Praktijklector", label: "Praktijklector", value: "Praktijklector" },
  { name: "Gastprofessor", label: "Gastprofessor", value: "Gastprofessor" },
  { name: "Projectmedewerker", label: "Projectmedewerker", value: "Projectmedewerker" },
  { name: "ATP", label: "ATP", value: "ATP" },
  { name: "Factuur", label: "Factuur", value: "Factuur" },
];

function EditModal({ data = {}, hideModal }) {
  const snapshot = useSelector(state => state.snapshot.fileName);

  const [saving, setSaving] = useState(false);
  const [invalidProgrammeModal, setInvalidProgrammeModal] = useState(null);

  const [firstName, setFirstName] = useState(data.firstName || "");
  const [lastName, setLastName] = useState(data.lastName || "");
  const [uNumber, setUnumber] = useState(data.uNumber || "");
  const [leaveSchedule, setLeaveSchedule] = useState(data.leaveSchedule || 0);
  const [percentageInUnit, setPercentageInUnit] = useState(data.percentageInUnit || 100);
  const [initials, setInitials] = useState(data.initials || "");
  const [nameOrangeGuide, setNameOrangeGuide] = useState(data.nameOrangeGuide || "");
  const [programmes, setProgrammes] = useState(data.programmes || [{ percentage: 100 }]);
  const [sumPercentage, setSumPercentage] = useState(0);
  const [comments, setComments] = useState(data.comments || "");
  const [fundingSource, setFundingSource] = useState(data.fundingSource || ""); // temporary, now used to indicate whether percentage is OK for unit or not
  const [noForfait, setNoForfait] = useState(data.noForfait || false);
  const [noProgrammeError, setNoProgrammeError] = useState(false);
  const [isSATUser, setIsSATUser] = useState(data.isSATUser || false);
  const [isInactive, setIsInactive] = useState(data.isInactive || false);
  const [color, setColor] = useState(data.color || "#A162D8");
  const [ambts, setAmbts] = useState(data.ambts ? data.ambts.map(f => ({ name: f.name, label: f.name, value: f.name })) : []);
  const [financeSources, setFinanceSources] = useState(data.financeSources || []);

  const openInvalidProgrammeModal = () => {
    setInvalidProgrammeModal(
      <InvalidProgrammeModal
        hideModal={() => {
          setInvalidProgrammeModal(null);
        }}
      />,
    );
  };

  useEffect(() => {
    setNoProgrammeError(false);

    let sum = 0;
    programmes.forEach(programme => {
      sum += programme.percentage;

      if (programme.programmeID === 0 || programme.programmeID === null) {
        setNoProgrammeError(true);
      }
    });
    setSumPercentage(sum);
  }, [programmes]);

  const removeProgramme = index => {
    const newProgrammes = [...programmes];
    newProgrammes.splice(index, 1);
    setProgrammes(newProgrammes);
  };

  const updateProgramme = (index, programme) => {
    const newProgrammes = [...programmes];
    newProgrammes[index] = programme;
    setProgrammes(newProgrammes);
  };

  const addProgramme = () => {
    setProgrammes([...programmes, {}]);
  };

  const removeFinanceSource = index => {
    const newFinanceSources = [...financeSources];
    newFinanceSources.splice(index, 1);
    setFinanceSources(newFinanceSources);
  };

  const updateFinanceSource = (index, programme) => {
    const newFinanceSources = [...financeSources];
    newFinanceSources[index] = programme;
    setFinanceSources(newFinanceSources);
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    setSaving(true);

    // eslint-disable-next-line no-restricted-syntax
    for (const programme of programmes) {
      setNoProgrammeError(false);
      if (programme.programmeID === 0 || programme.programmeID === null) {
        setNoProgrammeError(true);
        openInvalidProgrammeModal();
        setSaving(false);
        return;
      }
    }

    if (!noProgrammeError) {
      try {
        if (data.ID) {
          await api.updateCollega(data.ID, {
            firstName,
            lastName,
            uNumber,
            initials,
            leaveSchedule,
            percentageInUnit,
            programmes,
            comments,
            fundingSource,
            noForfait,
            isSATUser,
            financeSources,
            ambts,
            nameOrangeGuide,
            color,
            isInactive,
          });
        } else {
          await api.createCollega({
            firstName,
            lastName,
            uNumber,
            initials,
            leaveSchedule,
            percentageInUnit,
            programmes,
            comments,
            fundingSource,
            noForfait,
            isSATUser,
            financeSources,
            ambts,
            nameOrangeGuide,
            color,
            isInactive,
          });
        }

        //api.createSnapshot();
        toastService.send({ title: "Success", message: "Docent is opgeslagen" });
        hideModal();
      } catch (error) {
        toastService.send({ title: "An error occured", message: error });
      }
    }

    setSaving(false);
  };
  return (
    <Modal
      show
      onHide={() => {
        hideModal();
      }}
      backdrop="static"
      keyboard={false}
      centered
      size="lg">
      {invalidProgrammeModal}
      <Modal.Header closeButton>
        <Modal.Title>Docent {data.ID ? "bewerken" : "aanmaken"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <Form onSubmit={onFormSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3">
                  <Form.Label>Voornaam</Form.Label>
                  <Form.Control type="text" required onChange={e => setFirstName(e.target.value)} value={firstName} />
                </Form.Group>
                <Form.Group as={Col} className="mb-3">
                  <Form.Label>Achternaam</Form.Label>
                  <Form.Control type="text" required onChange={e => setLastName(e.target.value)} value={lastName} />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3">
                  <Form.Label>Initialen</Form.Label>
                  <Form.Control type="text" onChange={e => setInitials(e.target.value)} value={initials} />
                </Form.Group>

                <Form.Group as={Col} className="mb-3">
                  <Form.Label>U-Nummer</Form.Label>
                  <Form.Control type="text" onChange={e => setUnumber(e.target.value)} value={uNumber} />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3">
                  <Form.Label>Naam Oranje Gids</Form.Label>
                  <Form.Control type="text" onChange={e => setNameOrangeGuide(e.target.value)} value={nameOrangeGuide} />
                </Form.Group>

                <Form.Group as={Col} className="mb-3">
                  <Form.Label>OK unit?</Form.Label>
                  <Form.Control type="text" onChange={e => setFundingSource(e.target.value)} value={fundingSource} />
                </Form.Group>

                <Form.Group as={Col} className="mb-3">
                  <Form.Label>Kleur Kanban</Form.Label>
                  <Form.Control type="color" required onChange={e => setColor(e.target.value)} value={color} />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} className="mb-3">
                  <Form.Label>Verlofstelsel</Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    max="100"
                    step="0.5"
                    onChange={e => setLeaveSchedule(parseFloat(e.target.value, 10))}
                    value={leaveSchedule}
                  />
                </Form.Group>

                <Form.Group as={Col} className="mb-3">
                  <Form.Label>% in unit</Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    max="100"
                    step="0.5"
                    onChange={e => setPercentageInUnit(parseFloat(e.target.value, 10))}
                    value={percentageInUnit}
                  />
                </Form.Group>
              </Row>

              <Form.Group className="mb-3">
                <Row>
                  <Col xs={6}>
                    <Form.Label>Opleidingen</Form.Label>
                  </Col>
                  <Col xs={6} className="justify-content-end d-flex">
                    <Button variant="success" onClick={addProgramme}>
                      <span>
                        <i className="far fa-plus" /> Toevoegen{" "}
                      </span>
                    </Button>
                  </Col>
                </Row>
                {programmes.map((p, i) => (
                  <EditProgramme data={p} key={p.ID} removeProgramme={() => removeProgramme(i)} saveData={d => updateProgramme(i, d)} />
                ))}
              </Form.Group>

              <Form.Group className="mb-3 text-danger">
                {sumPercentage !== percentageInUnit - leaveSchedule && "Percentage in unit is niet gelijk aan de som van de percentages in opleidingen"}
              </Form.Group>

              <Form.Group className="mb-3 text-danger">{noProgrammeError && "Selecteer een opleiding voor ieder percentage!"}</Form.Group>

              {/* <Form.Group className="mb-3">
                <Form.Label>Kleur kanban</Form.Label>
                <Form.Control type="text" onChange={e => setColor(e.target.value)} value={color} />
                </Form.Group> */}

              <Form.Group className="mb-3">
                <Form.Check type="checkbox" label="Geen forfait" checked={noForfait} onChange={e => setNoForfait(e.target.checked)} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Check type="checkbox" label="SAT-gebruiker" checked={isSATUser} onChange={e => setIsSATUser(e.target.checked)} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Check type="checkbox" label="Inactief (verborgen in applicatie)" checked={isInactive} onChange={e => setIsInactive(e.target.checked)} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Row>
                  <Col xs={6}>
                    <Form.Label>Financieringsbron</Form.Label>
                  </Col>
                  <Col xs={6} className="justify-content-end d-flex">
                    <Button variant="success" onClick={() => setFinanceSources([...financeSources, {}])}>
                      <span>
                        <i className="far fa-plus" /> Toevoegen{" "}
                      </span>
                    </Button>
                  </Col>
                </Row>
                {financeSources.map((p, i) => (
                  <EditFinanceSource data={p} key={p.name} remove={() => removeFinanceSource(i)} saveData={d => updateFinanceSource(i, d)} />
                ))}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Ambt</Form.Label>
                <MultiSelect options={possibleAmbts} value={ambts} onChange={e => setAmbts(e)} labelledBy="Select" />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Opmerkingen</Form.Label>
                <Form.Control as="textarea" onChange={e => setComments(e.target.value)} value={comments} />
              </Form.Group>

              <Button variant="primary" type="submit" disabled={snapshot || saving}>
                {data.ID ? (
                  <span>
                    <i className="far fa-edit" /> {sumPercentage !== percentageInUnit - leaveSchedule ? " Ja ja, pas maar aan" : " Bewerk"}
                  </span>
                ) : (
                  <span>
                    <i className="far fa-plus" /> {sumPercentage !== percentageInUnit - leaveSchedule ? " Ja ja, voeg maar toe" : " Aanmaken"}
                  </span>
                )}
              </Button>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default EditModal;